<template>
  <div class="mt-3">
    <b-row>
      <b-col cols="12">
        <b-row class="mb-4">
          <b-col cols="12">
            <b-card style="width:90%;" class="mx-auto" header-tag="header">
              <b-row>
                <template v-for="(m, index) in model">
                  <b-col
                    :key="index"
                    cols="12"
                    sm="4"
                    md="4"
                    lg="4"
                    v-if="
                      (typeof m.disabled === 'undefined' || !m.disabled) &&
                        m.type !== 'hidden'
                    "
                  >
                    <detail-group-input
                      :key="index"
                      :type="m.type"
                      :label="typeof m.label === 'string' ? m.label : null"
                      :value="m.value"
                      :id="index"
                    >
                      <template
                        slot="content_status"
                        slot-scope="defaultSlotScope"
                      >
                        <div class="">
                          <span
                            :class="[
                              'badge',
                              defaultSlotScope.colData == 'unpaid'
                                ? 'badge-danger'
                                : 'badge-success',
                            ]"
                          >
                            {{ defaultSlotScope.colData }}
                          </span>
                        </div>
                      </template>
                      <template
                        slot="content_buyer_note"
                        slot-scope="defaultSlotScope"
                      >
                        {{ defaultSlotScope.colData.buyer_note }}
                      </template>
                      <template
                        slot="content_registrant"
                        slot-scope="defaultSlotScope"
                      >
                        {{ defaultSlotScope.colData.name }}
                      </template>
                      <template
                        slot="content_sub_total"
                        slot-scope="defaultSlotScope"
                      >
                        <span class="heading-color">{{
                          formatCurrency(defaultSlotScope.colData)
                        }}</span>
                      </template>
                      <template
                        slot="content_total"
                        slot-scope="defaultSlotScope"
                      >
                        <span class="heading-color">{{
                          formatCurrency(defaultSlotScope.colData)
                        }}</span>
                      </template>
                      <template
                        slot="content_is_active"
                        slot-scope="defaultSlotScope"
                      >
                        <span class="heading-color">{{
                          defaultSlotScope.colData == 1
                            ? "Aktif"
                            : "Tidak Aktif"
                        }}</span>
                      </template>
                    </detail-group-input>
                  </b-col>
                </template>
              </b-row>
              <h5>Info Data Jama'ah</h5>
              <b-row>
                <b-col cols="12" sm="12" md="3" lg="3">
                  <label class="form-label w-100" style="font-size: 80%;"
                    >Nama</label
                  >
                  <p class="heading-color">{{ pilgrim.name }}</p>
                </b-col>

                <b-col cols="12" sm="12" md="3" lg="3">
                  <label class="form-label w-100" style="font-size: 80%;"
                    >NIK</label
                  >
                  <p class="heading-color">{{ pilgrim.nik }}</p>
                </b-col>

                <b-col cols="12" sm="12" md="3" lg="3">
                  <label class="form-label w-100" style="font-size: 80%;"
                    >Nomor HP</label
                  >
                  <p class="heading-color">{{ pilgrim.phone_number }}</p>
                </b-col>

                <b-col cols="12" sm="12" md="3" lg="3">
                  <label class="form-label w-100" style="font-size: 80%;"
                    >Nomor Whatsapp</label
                  >
                  <p class="heading-color">{{ pilgrim.wa_number }}</p>
                </b-col>
              </b-row>
              <h5>Info Data Paket</h5>
              <b-row>
                <b-col cols="12" lg="12">
                  <table class="table table-striped table-hovered table-bordered">
                    <tr>
                      <th>Nama Paket</th>
                      <th>Tipe</th>
                      <th class="text-right">Harga</th>
                      <th class="text-right">Total Pembayaran</th>
                    </tr>
                    <tr>
                      <td>{{detail_package[0].orderdetailable.name}}</td>
                      <td>{{detail_package[0].orderdetailable.type}}</td>
                      <td class="text-right">{{formatCurrency(detail_package[0].orderdetailable.price)}}</td>
                      <td class="text-right">{{formatCurrency(detail_package[0].total_paid)}}</td>
                    </tr>
                  </table>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12">
        <div class="mb-5 deep-detail">
          <b-card style="width:90%;" no-body class="mx-auto card-custom">
            <b-tabs card pills>
              <b-tab title="History Payment" active>
                <div class="mb-5">
                  <el-table
                    stripe
                    :data="mutasi"
                    border
                    align="center"
                    style="width: 100%;"
                    empty-text="Data tidak ditemukan"
                  >
                    <el-table-column type="index" fixed />
                    <el-table-column prop="date" align="center" label="Date" />
                    <el-table-column label="Mutasi" align="center">
                      <el-table-column
                        align="right"
                        prop="kredit"
                        label="Kredit"
                      />
                      <el-table-column
                        align="right"
                        prop="debit"
                        label="Debit"
                      />
                    </el-table-column>
                    <el-table-column
                      prop="saldo"
                      label="Saldo"
                      align="right"
                    ></el-table-column>
                  </el-table>
                </div>
              </b-tab>
            </b-tabs>
          </b-card>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { setModelValue, formatModelValue } from "@/_helpers";
import detailGroupInput from "@/components/DetailGroupInput.vue";
import Order from "@/models/Order.js";
import HistoryModel from "@/models/PaymentHistory.js";

const OrderModel = new Order();
const paymentHistoryModel = new HistoryModel();
// const documentModel = new DokumenModel();

export default {
  components: {
    detailGroupInput,
  },
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      id: null,
      isLoading: false,
      apiUrl: {
        paymentHistory: paymentHistoryModel.endpoint + "?order_id=" + this.id,
      },
      documentsForms: [],
      model: OrderModel.init(),
      pilgrim: null,
      detail_package: [],
      payment: null,
      dokumen: null,
      mutasi: [],
      paymentHistory: {
        column: [
          {
            prop: "payment_method",
            label: "Metode",
            sortable: true,
            resizable: true,
            minWidth: 200,
          },
          {
            prop: "type",
            label: "Tipe",
            sortable: true,
            resizable: true,
            minWidth: 200,
          },
          {
            prop: "amount",
            label: "Harga",
            sortable: true,
            resizable: true,
            minWidth: 200,
            needCurrency: true,
          },
          {
            prop: "status",
            label: "Status",
            sortable: true,
            resizable: true,
            minWidth: 200,
          },
        ],
        items: [],
        dataParams: ["order_id"],
      },
    };
  },
  mounted: function() {
    this.getDetail();
    this.getMutasi(this.id);
    this.getDetailPaymentHistory();
  },
  computed: {
    // filterOrderLabel() {
    //   if (this.paymentHistory.items !== null) {
    //     return this.paymentHistory.items.filter(
    //       (dt) => dt.orderdetailable != null
    //     );
    //   }
    // },
  },
  methods: {
    formatCurrency(value) {
      return formatModelValue("price", value);
    },
    getDetail() {
      this.isLoading = true;
      OrderModel.find(this.id)
        .then((resp) => {
          this.isLoading = false;
          setModelValue(this.model, resp);
          this.detail_package = resp.details
          this.detail_package[0].total_paid = resp.total_paid
          if (this.$store.getters["account/accountData"].role !== "admin") {
            delete this.model["sub_total"];
            delete this.model["total"];
            delete this.model["total_paid"];
          }
          this.pilgrim = resp.pilgrim;
          this.paymentHistory.items = resp.details;
        })
        .catch((error) => {
          if (process.env.NODE_ENV !== "production") {
            console.error(error);
          }
          this.isLoading = false;
        });
    },

    getMutasi: async function(id) {
      const mutasi = await OrderModel.getPaymentHistory(id);
      const bindMutasi = [];
      let calSaldo = 0;
      mutasi.forEach((data) => {
        calSaldo = calSaldo + data.amount;
        bindMutasi.push({
          date: this.$moment(data.created_at).format("l"),
          kredit: data.type == "debit" ? "-" : this.formatCurrency(data.amount),
          debit: data.type == "debit" ? this.formatCurrency(data.amount) : "-",
          saldo: this.formatCurrency(calSaldo),
        });
      });

      this.mutasi = bindMutasi;
    },
  },
};
</script>
