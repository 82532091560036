<template>
  <div>
    <div class="mx-lg-5 mx-md-5 mx-4 p-lg-3 p-0">
      <b-form @submit.prevent="doFilter">
        <b-row>
          <b-col
            v-if="$route.path == '/transaksi/cek-data-transaksi'"
            cols="12"
            sm="12"
            md="9"
            lg="9"
          >
            <label for="filter-date-transaction" class="form-label"
              >Tanggal Transaksi</label
            >
            <date-picker
              class="form-control"
              format="DD-MM-YYYY"
              :need-label="true"
              v-model="filterDate"
              range
            />
          </b-col>
          <b-col
            v-if="$route.path == '/transaksi/cek-data-paket'"
            cols="12"
            sm="12"
            md="9"
            lg="9"
          >
            <label for="" class="form-label">Paket</label>
            <b-form-group
              id="fieldset-1"
              label-for="input-1"
              class="form-label"
            >
              <b-form-select
                v-model="filter.package_id"
                :options="optionPaket"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col
            v-if="$route.path == '/transaksi/cek-data-jamaah'"
            cols="12"
            sm="12"
            md="9"
            lg="9"
          >
            <label for="" class="form-label">Nama Jama'ah</label>
            <b-form-input v-model="filter.pilgrim"></b-form-input>
          </b-col>
          <b-col
            clear
            v-if="$route.path == '/transaksi/cek-data-virtual-account'"
            cols="12"
            sm="12"
            md="9"
            lg="9"
          >
            <label for="" class="form-label">Nomor Virtual Account</label>
            <b-form-input v-model="filter.nomor_va"></b-form-input>
          </b-col>
          <b-col
            class="mt-4 d-flex justify-content-end"
            cols="12"
            md="3"
            lg="3"
          >
            <b-btn type="submit" variant="primary">Cari</b-btn>
            <b-btn
              type="button"
              @click.prevent="clearForm"
              variant="warning"
              class="ml-3 text-white"
              >Clear</b-btn
            >
          </b-col>
        </b-row>
      </b-form>
    </div>
    <b-card
      style="width:90%; margin:auto;"
      class="mb-4 mt-5 mx-auto"
      v-if="$route.path != '/transaksi/cek-data-virtual-account'"
    >
      <div v-show="showTable">
        <paginated-table
          aksi-dot
          ref="dataTable"
          :delete-reason="false"
          :can-delete="false"
          :can-edit="false"
          :data-url="apiUrlTransaki"
          :dataParams="dataParams"
          :defaultDataParams="this.filter"
          :data-columns="columns"
          :with-empty-first="true"
          no-actions
        >
          <template slot="content_status" slot-scope="defaultSlotScope">
            <div class="text-center">
              <span
                :class="[
                  'badge',
                  (() => {
                    if (defaultSlotScope.colData.status == 'partial' && 'paid')
                      return 'badge-success';
                    if (defaultSlotScope.colData.status == 'unpaid')
                      return 'badge-danger';
                    // if (defaultSlotScope.colData.status == 'partial')
                    //   return 'badge-warning';
                  })(),
                ]"
              >
                {{ defaultSlotScope.colData.status }}
              </span>
            </div>
          </template>
          <template slot="content_payment_method" slot-scope="defaultSlotScope">
            <span>{{
              defaultSlotScope.colData.bank
                ? defaultSlotScope.colData.bank.name
                : "-"
            }}</span>
          </template>
          <template slot="content_package" slot-scope="defaultSlotScope">
            <span>{{
              defaultSlotScope.colData.details.length > 0
                ? defaultSlotScope.colData.details[0].orderdetailable.name
                : "-"
            }}</span>
          </template>
          <template slot="content_type" slot-scope="defaultSlotScope">
            <span>{{
              defaultSlotScope.colData.details.length > 0
                ? defaultSlotScope.colData.details[0].orderdetailable.type
                : "-"
            }}</span>
          </template>
          <template slot="content_nomor_va" slot-scope="defaultSlotScope">
            <span>{{
              defaultSlotScope.colData
                ? defaultSlotScope.colData.nomor_va
                : "-"
            }}</span>
          </template>
          <template slot="content_pilgrim" slot-scope="defaultSlotScope">
            <span>{{
              defaultSlotScope.colData.pilgrim
                ? defaultSlotScope.colData.pilgrim.name
                : "-"
            }}</span>
          </template>
        </paginated-table>
      </div>
      <div
        v-if="!showTable"
        style="min-height: 300px;"
        class="d-flex align-items-center justify-content-center"
      >
        {{
          $route.path
            .split("/")[2]
            .split("-")
            .map((data) => data[0].toUpperCase() + data.slice(1))
            .join(" ")
        }}
      </div>
    </b-card>
    <template v-else>
      <template v-if="detailId">
        <detail :id="detailId" />
      </template>
      <template v-else>
        <b-card style="width:90%; margin:auto;" class="mb-4 mt-4 mx-auto">
          <div
            style="min-height: 300px;"
            class="w-100 d-flex justify-content-center align-items-center"
          >
            <b-icon icon="search"></b-icon>
            Cari Data Transaksi
          </div>
        </b-card>
      </template>
    </template>
  </div>
</template>

<script>
import paginatedTable from "@/components/PaginatedTable.vue";
import formGroupInput from "@/components/FormGroupInput.vue";
import Package from "@/models/Package.js";
import Detail from "./Detail.vue";
import DatePicker from "vue2-datepicker";
import Order from "@/models/Order";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/id";

const PackageModel = new Package();
const OrderModel = new Order();

export default {
  components: {
    DatePicker,
    paginatedTable,
    Detail,
    formGroupInput,
  },

  data: () => ({
    detailId: null,
    showTable: false,
    SearchData: "",
    filterDate: [],
    optionPaket: [],
    apiUrlTransaki: OrderModel.endpoint + '?package_type=ibuluber',
    columns: [
      {
        prop: "created_at",
        label: "Tgl. Order",
        resizable: true,
        align: "left",
        sortable: false,
        minWidth: 150,
        formatThisDate: true,
      },
      {
        prop: "pilgrim",
        label: "Jama'ah",
        resizable: true,
        align: "center",
        sortable: false,
        minWidth: 150,
      },
      {
        prop: "nomor_va",
        label: "Nomor Va",
        resizable: true,
        align: "center",
        sortable: false,
        minWidth: 150,
      },
      // {
      //   prop: "total",
      //   label: "Amount",
      //   resizable: true,
      //   needCurrency: true,
      //   align: "center",
      //   sortable: false,
      //   minWidth: 150,
      // },
      {
        prop: "package",
        label: "Paket",
        resizable: true,
        align: "center",
        sortable: false,
        minWidth: 150,
      },
      {
        prop: "payment_method",
        label: "Jenis Pembayaran",
        resizable: true,
        align: "center",
        sortable: false,
        minWidth: 150,
      },
      {
        prop: "type",
        label: "Type",
        resizable: true,
        align: "center",
        sortable: false,
        minWidth: 150,
      },
      {
        prop: "status",
        label: "Status",
        resizable: true,
        align: "center",
        sortable: false,
        minWidth: 150,
      },
    ],
    filter: {
      pilgrim: "",
      start_date: "",
      end_date: "",
      package_id: "",
      nomor_va: "",
    },
    dataParams: ["nomor_va", "pilgrim", "start_date", "end_date", "package_id"],
  }),
  watch: {
    filterDate: function(value) {},
    filter: {
      handler: function(value) {
        // console.log(value)
      },
      deep: true,
    },
  },
  beforeMount() {
    this.$router.push({ query: { ...this.$route.query } }).catch(() => {});
  },
  created() {
    this.getPackage();
  },

  mounted: function() {
    this.clearForm();
  },

  methods: {
    clearForm() {
      this.showTable = false;
      this.detailId = null;
      this.$nextTick(() => {
        this.filterDate = [];
        this.filter = {
          pilgrim: null,
          user_name: null,
          start_date: null,
          end_date: null,
          package_id: null,
        };
        this.showTable = false;
      });
    },
    doFilter() {
      // check for filter by virtual account
      this.showTable = true;
      if (this.filter.nomor_va) {
        OrderModel.list({ nomor_va: this.filter.nomor_va }).then(
          (resp) => (this.detailId = resp.data[0].id)
        );
      }

      if (
        this.filterDate.length > 0 &&
        this.filterDate[0] != null &&
        this.filterDate[1] != null
      ) {
        this.filter.start_date = this.$moment(this.filterDate[0]).format(
          "YYYY-MM-DD"
        );
        this.filter.end_date = this.$moment(this.filterDate[1]).format(
          "YYYY-MM-DD"
        );
      } else {
        this.filter.start_date = null;
        this.filter.end_date = null;
      }

      this.$refs.dataTable.doFilter(this.filter);
      return;
    },
    getPackage() {
      PackageModel.list({
        limit: 10000,
        type: "ibuluber",
      }).then((res) => {
        res.data.map((d) => {
          if (d.type != "ibuluber") return;
          this.optionPaket.push({
            value: d.id,
            text: d.name,
          });
        });

        // console.log(this.optionPaket)
      });
    },
  },
};
</script>
